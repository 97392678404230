<template>
  <div class="page-container">
    <div class="page-route-select">
      <router-link to="/cliente/monitores/cotacoes" class="page-route-select-text">
        Home
      </router-link>
      <span class="material-icons page-route-select-icon">play_arrow</span>
      <div class="page-route-select-text">
        Fornecedores
      </div>
    </div>
    <div class="page-title">
      Fornecedores
    </div>
    <div class="providers-page__buttons-container">
      <Button class="action-buttons" :iconName="'person_add_alt'" title="Novo Fornecedor" :text="'Novo Fornecedor'" :action="() => { edit({}) }" />
      <Button class="action-buttons" :iconName="'people'" title="Vendedores" :text="'Vendedores'" :action="() => { $router.push('/fornecedores/vendedores') }" />
      <Button class="action-buttons" :iconName="'event_note'" title="Solicitações" :text="'Solicitações'" :action="() => { $router.push('/fornecedores/solicitacoes') }" />
      <Button class="action-buttons" :iconName="'inventory_2'" title="Produtos" :text="'Produtos'" :action="() => { $router.push('/fornecedores/produtos') }" />
    </div>
    <div class="providers-page__actions-container">
      <div class="providers-page__left-content">
        <StandardInput :placeholder="'Digite o nome'" 
                       :title="'Nome'" 
                       :action="setNameValue" 
                       :value="filters.name"
                       :type="'text'"
                       class="providers-page__select-input"/>
        <StandardInput :placeholder="'Digite o estado'" 
                       :title="'Estado'" 
                       :action="setStateValue" 
                       :value="filters.state"
                       :list="arraySelectState" 
                       :type="'select'"
                       class="providers-page__select-input"/>
        <cc-checkbox @change="(evt) => orderOnly = evt.target.checked" label="fornecedores com pedidos" />
        <cc-checkbox @change="(evt) => offerOnly = evt.target.checked" label="fornecedores com digitações" />
      </div>
      <div class="providers-page__right-content">
        <Button style="margin-top: 1.5em;" :iconName="'cancel'" title="Limpar Filtros" :text="'Limpar Filtros'" :action="clearFilters" />
      </div>
    </div>
    <div class="page-selected-filters-container">
	  <div v-if="filters.name" class="page-selected-filters">
        <div class="page-selected-filters-text">{{ filters.name }}</div>
        <span @click="setNameValue('')" class="material-icons page-selected-filters-icon">close</span>
      </div>
      <div v-if="filters.state" class="page-selected-filters">
        <div class="page-selected-filters-text">{{ filters.state.est_nome }}</div>
        <span @click="setStateValue('')" class="material-icons page-selected-filters-icon">close</span>
      </div>
    </div>
    <cc-loader v-show="is_loading" class="default" />
    <no-data-available v-if="total_items == 0 && !is_loading" :text="'Nenhum produto encontrado'" />
    <div v-show="!is_loading" class="product-page-table-container">
      <div v-show="total_items > 0">
        <div class="page-table-header desktop">
          <div class="page-table-header-text upload-width"></div>
          <div class="page-table-header-text id-width">ID</div>
          <div class="page-table-header-text name-width">Nome</div>
          <div class="page-table-header-text corporate-width">Razão</div>
          <div class="page-table-header-text cnpj-width">CNPJ</div>
          <div class="page-table-header-text phone-width">Telefone</div>
          <div class="page-table-header-text state-width">Estado</div>
          <div class="page-table-header-text city-width">Cidade</div>
          <div class="page-table-header-text actions-width">Ações</div>
        </div>
        <div v-for="(provider, idx) in providers" 
             :key="idx"
             :class="getSpecialBackground(idx)">

          <!-- desktop -->
          <div class="page-table-row desktop">
            <div class="page-table-desc-column upload-width ">
              <div class="page-table-header-mobile"></div>
              <div class="upload-cover-wrapper">
                <cc-logo :src="provider.cover_url" @click="() => select_file(provider)" />
                <input style="display: none;"
                      type="file"
                      :ref="'cover-file-' + provider.id"
                      @change="(evt) => update_cover(evt, provider)">
              </div>
              
            </div>
            <div class="page-table-desc-column id-width"><div class="page-table-header-mobile">ID</div>{{ provider.id }}</div>
            <div class="page-table-desc-column name-width"><div class="page-table-header-mobile">Nome</div>{{ provider.name }}</div>
            <div class="page-table-desc-column corporate-width"><div class="page-table-header-mobile">Razão</div>{{ provider.social_reazon }}</div>
            <div class="page-table-desc-column cnpj-width"><div class="page-table-header-mobile">CNPJ</div>{{ provider.cnpj  || "" | VMask('## ### ###/####-##') }}</div>
            <div class="page-table-desc-column phone-width">
              <div class="page-table-header-mobile">Telefone</div>
              <cc-whatsapp v-if="provider.phone" :phone="provider.phone" />
              <span v-else style="margin-left: 30px;">-</span>
            </div>
            <div class="page-table-desc-column state-width"><div class="page-table-header-mobile">Estado</div>{{ provider.head_office ? provider.head_office.state.name : '-' }}</div>
            <div class="page-table-desc-column city-width"><div class="page-table-header-mobile">Cidade</div>{{ provider.head_office ? provider.head_office.city.name : '-' }}</div>
            <div class="page-table-desc-column actions-width">
              <div class="page-table-header-mobile">Ações</div>
              <span class="material-icons-outlined edit-icon"
                    title="Editar Fornecedor"
                    @click="edit(provider)">
                edit
              </span>
              <span class="material-icons-outlined group-icon"
                    title="Vendedores"
                    @click="show_users(provider)">
                group
              </span>
              <span class="material-icons-outlined delete-icon"
                    title="Remover vendedor"
                    @click="remove_provider(provider)">
                delete
              </span>
              <span class="material-icons-outlined permission-icon" title="Permissões" @click="manage_permissions(provider)">
                contact_mail
              </span>
              <span class="material-icons-outlined permission-icon2" title="Associar Categorias" @click="show_associate_categories(provider)">
                shutter_speed
              </span>
            </div>
          </div>
          <!-- mobile -->
          <div class="mobile" style="padding: 1vh 0;">
            <div class="upload-cover-wrapper">
              <cc-logo :src="provider.cover_url" @click="() => select_file(provider)" />
              <input style="display: none;"
                    type="file"
                    :ref="'cover-file-' + provider.id"
                    @change="(evt) => update_cover(evt, provider)">
            </div>
            <div class="mobile-table-row" style="margin-top: 2vh;">
              <div style="display: flex; align-items: center;" class="page-table-header-mobile" v-on:click="() => {provider.isExpanded = !provider.isExpanded; $forceUpdate()}">
                <span v-if="!provider.isExpanded" class="material-icons-outlined mobile-expand-icon">expand_more</span>
                <span v-else class="material-icons-outlined mobile-expand-icon">expand_less</span>
                {{ provider.name }}
              </div>
              {{ provider.id }}
            </div>
            <div class="mobile-table-row">
              <div>
                <div class="page-table-header-mobile">Razão</div>{{ provider.social_reazon }}
              </div>
              <div class="mobile-colum-end" style="width: 50%;">
                <div class="page-table-header-mobile">CNPJ</div>{{ provider.cnpj  || "" | VMask('## ### ###/####-##') }}
              </div>
              
            </div>
            <div class="mobile-table-row" v-if="provider.isExpanded">
              <div >
                <div class="page-table-header-mobile">Telefone</div>
                <cc-whatsapp v-if="provider.phone" :phone="provider.phone" />
                <span v-else >--</span>
              </div>
              <div  class="mobile-colum-center">
                <div class="page-table-header-mobile">Estado</div>{{ provider.head_office ? provider.head_office.state.name : '-' }}
              </div>
              <div  class="mobile-colum-end">
                <div class="page-table-header-mobile">Cidade</div>{{ provider.head_office ? provider.head_office.city.name : '-' }}
              </div>
            </div>

            <div class="mobile-table-row">
              <span class="material-icons-outlined edit-icon"
                    title="Editar Fornecedor"
                    @click="edit(provider)">
                edit
              </span>
              <span class="material-icons-outlined group-icon"
                    title="Vendedores"
                    @click="show_users(provider)">
                group
              </span>
              <span class="material-icons-outlined delete-icon"
                    title="Remover vendedor"
                    @click="remove_provider(provider)">
                delete
              </span>
              <span class="material-icons-outlined permission-icon" title="Permissões" @click="manage_permissions(provider)">
                contact_mail
              </span>
              <span class="material-icons-outlined permission-icon2" title="Associar Categorias" @click="show_associate_categories(provider)">
                shutter_speed
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="box-footer clearfix not-print">
        <cc-paginacao :key="'pag'"
                      classes="orange"
                      :items_by_page_value="items_by_page_value"
                      :total="total_items"
                      :page="paginaAtual"
                      :page_limit="11"
                      v-on:change="load($event)">
        </cc-paginacao>
      </div>
    </div>
    <SellersModal @close="is_provider_users_modal_open=false"
                  v-if="is_provider_users_modal_open"
                  :provider="current_provider" />
    <PermissionsModal v-if="showClientPermissionModal"
                      :closeDialog="closeClientPermissionModal"
                      :owner_name="current_provider.name"
                      :id="current_provider.id"
                      :type="'PROVIDER'" />
    <ProviderModal :id="current_provider.id"
                   @close="close_modal"
                   v-if="is_provider_modal_open" />
    <AssociateCategoryModal @close="is_provider_associate_modal_open=false"
                            v-if="is_provider_associate_modal_open"
                            :provider="current_provider" />
  </div>
    <!-- <div class="monitors">
      <div class="body">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <form @submit.prevent="load">
                            <div class="row py-3">
                                <div class="col">
                                    <label for="" class="label-control">Nome</label>
                                    <cc-search v-model="filters.name" :noicon="true" />
                                </div>
                                <div class="col col-2">
                                    <label for="" class="label-control">Estado</label>
                                    <cc-select-v2
                                        :tracker="'est_id'"
                                        :text="'est_nome'"
                                        :searchable="true"
                                        :options="states"
                                        v-model="filters.state" />
                                </div>
                                <div class="col">
                                    <label for="" class="label-control">&nbsp;</label>
                                    <cc-button type="submit"
                                        class="w-100"
                                        :nolink="true"
                                        :classes="'fixed secondary'"
                                        :icon="'fa fa-search fa-lg'"/>
                                </div>
                                <div class="col">
                                    <label for="" class="label-control">&nbsp;</label>
                                    <cc-button
                                        :nolink="true"
                                        :classes="'fixed danger-outline'"
                                        :icon="'far fa-trash-alt'"
                                        :content="'Limpar Filtros'"
                                        @click="filters={}" />
                                </div>
                                <div class="col">
                                    <label for="" class="label-control">&nbsp;</label>
                                    <cc-button
                                        :classes="'fixed secondary'"
                                        :icon="'fas fa-boxes'"
                                        :content="'Produtos'"
                                        :link="'/fornecedores/produtos'" />
                                </div>
                                <div class="col">
                                    <label for="" class="label-control">&nbsp;</label>
                                    <cc-button
                                        :classes="'fixed secondary'"
                                        :icon="'fas fa-file-alt'"
                                        :content="'Solicitações'"
                                        :link="'/fornecedores/solicitacoes'" />
                                </div>
                                <div class="col">
                                    <label for="" class="label-control">&nbsp;</label>
                                    <cc-button
                                        :classes="'fixed theme-1 active'"
                                        :icon="'fas fa-user-friends'"
                                        :content="'Vendedores'"
                                        :link="'/fornecedores/vendedores'" />
                                </div>
                                <div class="col">
                                    <label for="" class="label-control">&nbsp;</label>
                                    <cc-button
                                        :classes="'fixed success'"
                                        :icon="'fas fa-plus'"
                                        :content="'Novo Fornecedor'"
                                        @click="edit({})"
                                        :nolink="true" />
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="col-12 col-12 p-4 shadow rounded">
                        <cc-loader v-show="is_loading" class="default" />
                        <table class="table" :class="{ hidden : is_loading }">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Nome</th>
                                    <th>Razão</th>
                                    <th>CNPJ</th>
                                    <th>Telefone</th>
                                    <th>Estado</th>
                                    <th>Cidade</th>
                                    <th class="text-center">Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(provider) in providers" v-bind:key="provider.id">
                                    <td>
                                        <cc-logo :src="provider.cover_url" @click="() => select_file(provider)" />
                                        <input
                                            style="display:none;"
                                            type="file"
                                            :ref="'cover-file-' + provider.id"
                                            @change="(evt) => update_cover(evt, provider)">
                                    </td>
                                    <td>{{ provider.name }}</td>
                                    <td>{{ provider.social_reazon }}</td>
                                    <td>{{ provider.cnpj  || "" | VMask('## ### ###/####-##') }}</td>
                                    <td><cc-whatsapp :phone="provider.phone" /></td>
                                    <td>{{ provider.head_office ? provider.head_office.state.name : '-' }}</td>
                                    <td>{{ provider.head_office ? provider.head_office.city.name : '-' }}</td>
                                    <td class="text-center actions">
                                        <i class="fa fa-pencil remove-btn"
                                            @click="edit(provider)" />
                                        <i class="fas fa-user-friends seller-icon"
                                            title="Vendedores"
                                            @click="show_users(provider)"></i>
                                        <i title="Remover vendedor"
                                            class="fa fa-trash"
                                            @click="remove_provider(provider)"></i>
                                        <i title="Permissões"
                                            @click="manage_permissions(provider)"
                                            class="far fa-id-card"></i>
                                        <i title="Associar Categorias"
                                            @click="show_associate_categories(provider)"
                                            class="fa fa-connectdevelop"></i>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="box-footer clearfix not-print">
                    <cc-paginacao
                        :key="'pag'"
                        classes="orange"
                        :items_by_page_value="items_by_page_value"
                        :total="total_items"
                        :page="paginaAtual"
                        :page_limit="11"
                        v-on:change="load($event)"></cc-paginacao>
                </div>
            </div>
            <SellersModal
                @close="is_provider_users_modal_open=false"
                v-if="is_provider_users_modal_open"
                :provider="current_provider" />
            <PermissionsModal
                @close="is_provider_permissions_modal_open=false"
                v-if="is_provider_permissions_modal_open"
                :owner_name="current_provider.name"
                :id="current_provider.id"
                :type="'PROVIDER'" />
            <ProviderModal
                :id="current_provider.id"
                @close="close_modal"
                v-if="is_provider_modal_open" />
            <AssociateCategoryModal
                @close="is_provider_associate_modal_open=false"
                v-if="is_provider_associate_modal_open"
                :provider="current_provider" />
        </div>
    </div> -->
</template>

<script>
import Paginacao from "@/components/cliente/base-components/Pagination";
import ProviderService from "@/services/v3/provider.service";
import ProviderServiceV1 from "@/services/ProviderService";
import SellersModal from "@/modals/providers/sellers/sellers.modal"
import PermissionsModal from "@/modals/permissions/index.modal"
import AssociateCategoryModal from "@/modals/providers/associate-category/category.modal"
import ErrorHandlerService from "@/services/ErrorHandlerService";
import { loaderMixin } from '@/mixins/sweet-loader.mixin'
import ProviderModal from "@/modals/provider/new/new-provider.modal";
import UserService from "@/services/v1/user.service"
import Button from '@/components/ui/buttons/StandardButton.vue'
import StandardInput from '@/components/ui/inputs/StandardInput.vue'

export default {
    mixins: [ loaderMixin ],
    components: {
        ProviderModal,
        SellersModal,
        ccPaginacao: Paginacao,
        PermissionsModal,
        AssociateCategoryModal,
        Button,
        StandardInput,
    },
    data(){
        return{
            arraySelectState: [],
            showClientPermissionModal: false,
            image:"/images/search.png",
            is_loading: false,
            paginaAtual: 1,
            total_items: 1,
            items_by_page_value: 15,
            svc: new ProviderService(),
            svc_v1: new ProviderServiceV1(),
            user_svc: new UserService(),
            providers: [],
            states: [],
            orderOnly: false,
            offerOnly: false,
            is_provider_modal_open: false,
            is_provider_users_modal_open: false,
            is_provider_permissions_modal_open: false,
            is_provider_associate_modal_open: false,
            filters: {
                page: 1
            },
            current_provider: null,
            breadcrumbs: [
                {
                    name: "Fornecedores",
                    route: 'sellers'
                }
            ]
        }
    },
    watch: {
      orderOnly () {
        this.load()
      },
      offerOnly () {
        this.load()
      }
    },
    methods: {
      setNameValue(value){
		    this.filters.name = value
        this.load(this.page) 
	  	},
      setStateValue(value){
		    this.filters.state = value
        this.load(this.page) 
	  	},
      clearFilters() {
        this.filters = {}
        this.load(this.page) 
      },
      closeClientPermissionModal: function() {
        this.showClientPermissionModal = false;
      },
        getSpecialBackground (idx) { if (idx % 2 != 0) return 'page-table-line-special' },
        manage_permissions(provider) {
            //this.is_provider_permissions_modal_open = true
            this.showClientPermissionModal = true;
            this.current_provider = provider
        },
        select_file(provider) {
            this.$refs['cover-file-'+provider.id][0].click()
        },
        update_cover(evt, provider) {
            let file = evt.target.files[0];
            this.svc.upload_cover(provider.id, file).then(response => {
                this.load()
            })
        },
        close_modal(reload = false) {
            this.is_provider_modal_open = false
            this.current_provider = null
            if(reload) {
                this.load()
            }
        },
        edit(provider) {
            this.current_provider = provider
            this.is_provider_modal_open = true
        },
        show_users(provider) {
            this.is_provider_users_modal_open = true
            this.current_provider = provider
        },

        show_associate_categories(provider) {
            this.is_provider_associate_modal_open = true
            this.current_provider = provider
        },
        load_region_data() {
		  	  return this.user_svc.region_data().then(response => response.data).then(data => {
				    this.states = data.estados
            data.estados.forEach(element => {
              this.arraySelectState.push({
                text: element.est_nome,
                value: element
              })
            })
		    	})
	     	},
        remove_provider(provider) {
            this.confirm_action({
                message: `Confirma remoção do fornecedor ${provider.name}?` ,
                callback: () => this.remove(provider.id)
            })
        },
        remove(provider_id) {
            return this.svc.remove(provider_id).then(() => this.load())
        },
        load(new_page = this.paginaAtual) {
            this.paginaAtual = new_page;
            this.is_loading = true;
            let params = {
                name: this.filters.name,
                state: this.filters.state ? this.filters.state.est_id : null,
                page: this.paginaAtual,
                with_orders: this.orderOnly ? 1 : 0,
                with_offers: this.offerOnly ? 1 : 0
            }
            this.svc.load(params)
            .then(response => response.data)
            .then(response => {
                this.providers = response.data;
                this.total_items = response.meta.total;
                this.is_loading = false;
            }).catch(error => {
                this.is_loading = false;
                ErrorHandlerService.handle(error, this.$store);
            });
        }
    },
    created() {
      this.load();
      this.load_region_data()
      this.$store.dispatch('set_breadcrumbs', this.breadcrumbs);
    }
}
</script>

<style lang="scss" scoped>
    @import '@/sass/commons/_tables';

    .seller-icon {
        color: $blue1;
        padding: 0 0.6rem;
        font-size: 1rem;
        &:hover {
            cursor: pointer;
        }
    }

    .rate {
        background-color: white;
        padding: 0.5rem 0.5rem;
        border-radius: 5px;
        color: black;
        cursor: pointer;
        i {
            font-size: 1.3rem;
            color: $yellow1;
        }
        &:hover {
            color: white;
            background-color: $blue1;
            i {
                color: white;
            }
        }
    }

    .actions {
        justify-content: center;
        i {
            font-size: 1.2rem;
            cursor: pointer;
            &.fa-trash {
                color: red;
            }
            &.fas.fa-user-lock {
                color: lightseagreen;
            }
        }
    }

.page-container {
  font-size: 1vw;
  margin-right: 4em;
}
.page-route-select {
  display: flex;
  align-items: center;
}
.page-route-select-text {
  font-weight: 400;
  font-size: 1.36em;
  line-height: 17px;
  color: #ABABAB;
  margin-right: 20px;
  cursor: pointer;
}
.page-route-select-icon {
  color: var(--primary-color);
  margin-right: 20px;
}
.page-title {
  font-weight: 600;
  font-size: 2.72em;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #605F5F;
  margin-top: 45px;
}
.providers-page__buttons-container {
  display: flex;
  flex-direction: row-reverse;
  margin: 2em 0em;
}
.action-buttons {
  margin-left: 20px;
}
.clients-page__product-input-container {
  display: flex;
  align-items: center;
}
.clients-page__product-input {
  border: 1px solid #c2c2c3;
  font-size: 17px;
  padding: 11px;
  border-radius: 5px;
  width: 30vw;
}
.clients-page__product-input:focus {
  border: 1px solid #c2c2c3 !important;
}
.search-icon {
  position: relative;
  left: -35px;
  color:  #606060;
}
.providers-page__actions-container {
  display: flex;
  justify-content: space-between;
}
.providers-page__left-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 80%;
}
.providers-page__actions-wrapper {
  margin-right: 20px;
}
.providers-page__input-title-wrapper {
  padding-bottom: 5px;
}
.providers-page__input-title {
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #898989;
  font-size: 1.6em;
}
.providers-page__right-content {}
.select-input-width {
  width: 30vw !important;
}
.page-selected-filters-container {
  display: flex;
  margin: 1em 0em;
}
.page-selected-filters {
  background: #FCF9F7;
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 7px 12px;
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.page-selected-filters-text {
  font-weight: 300;
  font-size: 14px;
  letter-spacing: 0.15px;
  color: var(--primary-color);
  margin-right: 5px;
}
.page-selected-filters-icon {
  color: var(--primary-color);
  cursor: pointer;
}
.product-page-table-container {
  margin: 3em 0em;
}
.page-table-header {
  background: #FFFEFC;
  border: 0.5px solid #E5E5E5;
  font-weight: 400;
  color: #605F5F;
  display: flex;
  padding: 1em 0em;
}
.page-table-header-text {
  padding-left: 10px;
  font-weight: 600; 
  font-size: 1.19em;
  color: #505050;
}
.page-table-row {
  display: flex;
  align-items: center;
  font-weight: 300;
  font-size: 1.0em;
  color: #605F5F;
}
.page-table-desc-column {
  padding: 0.5em 0.7em;
}
.page-table-line-special {  
  background: #F7F7F7;
}
.upload-width {
  width: 4%;
}
.name-width {
  width: 20%;
}
.id-width {
  width: 7%;
}
.corporate-width {
  width: 30%;
}
.cnpj-width {
  width: 14%;
}
.phone-width {
  width: 10%;
}
.state-width {
  width: 10%;
}
.city-width {
  width: 10%;
}
.actions-width {
  width: 16%;
}
.edit-icon {
  margin-right: 8px;
  color: #606060;
  cursor: pointer;
  font-size: 1.8em;
}
.group-icon {
  margin-right: 8px;
  color: #17A2B8;
  font-size: 1.8em;
  cursor: pointer;
}
.delete-icon {
  margin-right: 8px;
  font-size: 1.8em;
  color: #CB4243;
  cursor: pointer;
}
.permission-icon {
  height: 100%;
  cursor: pointer;
  font-size: 1.8em;
  margin-right: 8px;
  color: green;
}
.permission-icon2 {
  height: 100%;
  cursor: pointer;
  font-size: 1.8em;
  margin-right: 8px;
  color: orange;
}
.upload-cover-wrapper {
  width: 35px;
  height: 35px;
  padding: 5px 7px;
  background-color: #D9D9D9;
  border-radius: 50%;
}
.page-table-header-mobile{
  display: none;
}
.providers-page__select-input{
  width: 30%;
}
.mobile{display: none;}

@media only screen and (max-width: 850px) {
  .page-container{
    font-size: 1.5vw;
  }
  .mobile{display: block;}
  .mobile-table-row{
    display: flex;
    justify-content: space-between;
    color: #605F5F;
  }
  .mobile-colum-end{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: end;
  }
  .mobile-colum-center{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .mobile-expand-icon{
    font-size: 2em;
    color: var(--primary-color);
  }
  .page-container{
    margin-right: 5vw;
  }
  .page-container{
    font-size: 2vw;
  }
  .desktop{display: none;}
  .page-table-row{
    flex-direction: column;
    justify-content: center;
    font-size: 2.7vw;
  }
  .upload-width {
    width: 100%;
    display: flex;
  }
  .name-width {
    display: flex;
    width: 100%;
    font-size: 1.5em;
  }
  .id-width {
    display: flex;
    font-size: 1.5em;
    width: 100%;
  }
  .corporate-width {
    display: flex;
    font-size: 1.5em;
    text-align: end;
    width: 100%;
  }
  .cnpj-width {
    display: flex;
    font-size: 1.5em;
    width: 100%;
  }
  .phone-width {
    display: flex;
    font-size: 1.5em;
    width: 100%;
  }
  .state-width {
    display: flex;
    font-size: 1.5em;
    width: 100%;
  }
  .city-width {
    display: flex;
    font-size: 1.5em;
    width: 100%;
  }
  .actions-width {
    display: flex;
    font-size: 1.5em;
    width: 100%;
  }
  .page-table-header-mobile{
    display: block;
    color: #605F5F;
    font-weight: bold;
  }
  .providers-page__actions-container{
    flex-direction: column;
  }
  .providers-page__left-content{
    width: 100%;
  }
  .action-buttons{width: 22%;}
  .providers-page__buttons-container{
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 0;
  }
  .action-buttons{
    margin-left: 0;
    margin-bottom: 1vh;
    width: 45%;
  }
}
@media only screen and (max-width: 500px){
  
  .page-container{
    font-size: 4vw;
  }
  .page-table-row{
    font-size: 3.2vw;
  }
}

</style>
