import http from "../http";
import Service from "../../Service";

/**
 * @type ProfileService
 */
export default class CategoryService extends Service {

    load_categories() {
        return http.get(`admin/providers/categories`, {
            headers: this.getHeaders()
        });
    }

    load_categories_provider(id) {
        return http.get(`admin/providers/${id}/categories`, {
            headers: this.getHeaders()
        });
    }

    update(provider_id, categories) {
		return http.put(`admin/providers/${provider_id}/categories`, categories, {
			headers: this.getHeaders()
		});
	}

}
